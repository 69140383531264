import React from "react";
// import { Link } from "gatsby"
import { EconomicCalenderQuery } from "../../prismic/staticQueries";
import { connect, useSelector } from "react-redux";

import Layout from "../../layout";
import { BreadCrumbBanner, Subscription } from "../../components/common";
import BANNER from "../../images/siteImages/economicCalendar/ECONOMIC_CALENDAR_BANNER.jpg";

// import { Tab, Tabs, Row, Nav, Col } from "react-bootstrap"
const OurMission = () => {
  const language = useSelector((state) => state.language);
  const { banner_image, page_heading, page_description } =
    EconomicCalenderQuery(language);
  return (
    <>
      <Layout>
        <BreadCrumbBanner heading={{}} banner_image={BANNER} />

        <section
          className="team-profile pt-5"
          style={{ background: "#fbfbfd" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="team-details">
                  <div className="m-bottom-30">
                    <h4 className="color-primary">{page_heading.text}</h4>
                  </div>
                  <p>{page_description.text}</p>
                </div>
              </div>
            </div>
          </div>
          <br />
        </section>

        <section
          className="pb-5"
          style={{ background: "linear-gradient(to bottom, #fbfbfd, #f7f7f7)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="m-bottom-65">
                  <div
                    className="divider text-center"
                    style={{ height: "750px" }}
                  >
                    {/* <script type="text/javascript" src="https://c.mql5.com/js/widgets/calendar/widget.js?6"></script> */}
                    <iframe
                      title="economic calender"
                      scrolling="no"
                      allowtransparency="true"
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      src="https://www.mql5.com/en/economic-calendar/widget?mode=2&amp;utm_source=accuindex.com"
                    ></iframe>
                    <div id="economicCalendarWidget"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Subscription />
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(OurMission);
